<template>
	<div class="cluesdetail">
		<div class="topde">
			<div class="box1">
				<div class="box2"></div>
				<div class="box3">商机管理 ></div>
				<div class="box4">基本信息</div>
			</div>
	
		</div>
		<div class="box5">
			<div class="box6">
				<div class="box7">
					<div class="box7title">
						<div class="titletext">
							<div>1</div>
							<span>基础信息</span>
						</div>
						
					</div>
					<div class="box7content">
						<div class="onecontent">
							<span>公司名称：</span>
							<p>{{customerInfo.customerName}}</p>
						</div>
						<div class="onecontent" v-if="customerInfo.companySetting">
							<span>公司类型：</span>
							<p>{{customerInfo.companySetting.typeName}}</p>
						</div>
						<div class="onecontent" v-if="customerInfo.companySetting">
							<span>所属行业：</span>
							<p>{{customerInfo.companySetting.industryName}}</p>
						</div>
						<div class="onecontent">
							<span>位置信息：</span>
							<p>{{customerInfo.address}}</p>
						</div>
						<div class="onecontent">
							<span>备  注：</span>
							<p>{{customerInfo.customerDesc}}</p>
						</div>
						<div class="onecontent" style="align-items: flex-start;">
							<span>联系方式：</span>
							<div style="width: 80%;" class="allperson">
								<div v-for="item in customerInfo.contactVo" :key="item.contactId" style="max-width: 80%;">
									<!-- 电话: {{item.contactPhone || '暂无'}} -->
									<!-- <span>姓名: {{item.userName}}</span> -->
									<span>{{item.contactPhone}}({{item.userName}})</span>
									<!-- <span>职位: {{item.positionName || '暂无'}}</span> -->
									   
								</div>
								
							</div>
							
						</div>
						<div class="onecontent">
							<span>归 属 人 ：</span>
							<p>{{customerInfo.belongRealName}}</p>
						</div>
						<div class="onecontent" v-if="customerInfo.companySetting">
							<span>线索来源：</span>
							<p>{{customerInfo.companySetting.sourceName}}</p>
						</div>
					</div>
				</div>
				<div class="box7">
					<div class="box7title">
						<div class="titletext">
							<div>2</div>
							<span>系统信息</span>
						</div>
					</div>
					<div class="box7content">
						<div class="onecontent">
							<span>跟进次数：</span>
							<p>{{customerInfo.followTimes}}</p>
						</div>
						<div class="onecontent">
							<span>商 机 数 ：</span>
							<p>{{shangjiList.length}}</p>
						</div>
						<div class="onecontent">
							<span>前归属人：</span>
							<p>{{customerInfo.originalBelongUserName}}</p>
						</div>
						<div class="onecontent">
							<span>合作时间：</span>
							<p>{{customerInfo.cooperationTime}}</p>
						</div>
					</div>
				</div>
				<div class="box7">
					<div class="box7title">
						<div class="titletext">
							<div>3</div>
							<span>相关商机</span>
						</div>
						
					</div>
					<div class="box7content" style="padding-right: 1.25em;">
						<div class="oneshangji" v-for="(item,index) in shangjiList" :key="item.chanceId">
							<div class="shangjititle">{{index > 8 ? index+1 : ('0'+(1+index))}} {{item.chanceTitle}}</div>
							<div class="shangjicontent">
								{{item.chanceDesc}}
							</div>
							<div class="box12" v-if="item.baojiaShow">
								<span>报价方案：</span>
								<img src="../../assets/customer/image1.png" />
								<template v-for="(item1,index1) in item.docList">
									<p style="margin-right: 0.625rem;" v-if="item1.docType == 0">{{item1.docName}}</p>
								</template>
								
							</div>
							<div class="box12" v-if="item.shejiShow">
								<span>设计方案：</span>
								<img src="../../assets/customer/image1.png" />
								<template v-for="(item1,index1) in item.docList">
									<p style="margin-right: 0.625rem;" v-if="item1.docType == 1">{{item1.docName}}</p>
								</template>
							</div>
						</div>
			
					</div>
				</div>
			</div>
			<div class="box8">
				<div class="box7title">
					<div class="titletext">
						<div>4</div>
						<span>跟进记录</span>
					</div>
					
				</div>
				<div class="box11">
					
					<div class="oneinfo" v-for="(item,index) in genjinList" :key="index">
						<div class="box11line"></div>
						<div class="time">
							<span>{{item.year}}</span>
							<p>{{item.hours}}</p>
						</div>
						<div class="point"></div>
						<div class="box10">
							<div class="box10title">
								<img src="../../assets/baseimg/image10.png" >
								<span>{{item.realName}}</span>
							</div>
							<div class="box10content">
								<div class="contenttitle">
									<!-- 方案洽谈阶段 -->
								</div>
								<div class="contenttext">{{item.followContent}}</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { customerInfo,customerChanceList,customerFollowList,shiftCustomerToSea,deleteCustomer } from "@/network/api.js"

export default {
	data() {
		return {
			type: '',
			customerId: '',
			customerInfo: {},
			shangjiList: [],   // 商机列表
			genjinList: [],   // 跟进列表

		}
	},
	name: 'shangjiDetail',

	created() {
		this.type = this.$route.query.type
		this.customerId = this.$route.query.customerId || ''   // 获取线索（客户）ID
		if (this.customerId) {
			this._customerInfo()
			this._customerChanceList()
			this._customerFollowList()
		}
		
	},
	methods: {
		// 点击删除
		clickDelete() {
			this.$refs.deleteclues.dialogVisible = true
		},
		// 删除客户
		async _deleteCustomer() {
			const res = await deleteCustomer({
				customerId: this.customerId
			})
			this.$myMessage('删除成功','success')
			this.$refs.deleteclues.dialogVisible = false
			this.$router.back()
		},
		// 点击转入公海出现弹框
		showZhuan() {
			this.$refs.zhuanSea.dialogVisible = true
		},
		// 转入公海
		async _shiftCustomerToSea() {
			const res = await shiftCustomerToSea({
				customerId: this.customerId
			})
			this.$myMessage('转入成功','success')
			this.$refs.zhuanSea.dialogVisible = false
		},
		// 获取跟进列表
		async _customerFollowList() {
			const res = await customerFollowList({customerId: this.customerId })
			console.log('获取跟进',res);
			if (res.data.length > 0) {
				res.data.forEach(item => {
					item.year = item.followTime.split(' ')[0]
					item.hours = item.followTime.split(' ')[1]
				})
			}
			this.genjinList = res.data || []
		},
		// 跟新数据
		updataInfo() {
			this._customerChanceList()
			this._customerInfo()
			this._customerFollowList()
		},
		// 获取客户的商机列表
		async _customerChanceList() {
			const res = await customerChanceList({customerId: this.customerId})
			console.log('商机列表',res);
			res.data.forEach(item => {
				item.baojiaShow = item.docList.some(item1 => item1.docType === 0)
				item.shejiShow = item.docList.some(item1 => item1.docType === 1)
				
			})
			
			this.shangjiList = res.data || []
		},
		// 获取线索详情
		async _customerInfo() {
			const res = await customerInfo({customerId: this.customerId})
			console.log('线索详情',res);
			this.customerInfo = res.data
		},
		// 点击出现编辑弹框
		showEdit() {
			let info = this.customerInfo
			this.$refs.editClues.ruleForm = {
				address: info.address, //公司地址
				companyIndustry: info.companySetting.industryId, //所属行业id
				companyType: info.companySetting.typeId, //公司类型id
				contactParams: info.contactVo, //联系人
				customerDesc: info.customerDesc, // 公司简介
				customerId: info.customerId, // 客户id
				customerName: info.customerName, //公司名称
				customerSource: info.companySetting.sourceId, //线索来源
				customerStatus: info.statusId, //客户状态
				customerTag: info.companySetting.tagId, // 标签id
				latitude: '', // 纬度
				longitude: '' // 经度
			},
			this.$refs.editClues.drawer = true
		},
		// 点击出现新增跟进
		showAddGen() {
			this.$refs.addGenjin.dialogVisible = true
			this.$refs.addGenjin.addinfo.customerId = this.customerId
		},
		// 点击出现增加商机弹框
		showAdd() {
			this.$refs.addShangji.drawer = true
			this.$refs.addShangji.ruleForm.customerId = this.customerId
		}
	}
};
</script>

<style scoped lang="scss">
	.allperson {
		span {
			margin: 0 1.5em 0.3em 0;

			color: #3266F7 !important;
			font-size: 0.875em !important;
		}
		div {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.box12 {
		display: flex;
		align-items: center;
		margin: 0.9375em 0 0 0;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #808080;
		}
		img {
			width: 0.75em;
			height: 1em;
			margin: 0 0.625em;
		}
		p {
			font-size: 0.875em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #2e77f6;
		}
	}
	.box11line {
		right: calc(70% + 1.25em) ;
		position: absolute;
		width: 1px;
		height: 10.125em;
		background-color: #EEEEEE;
	}
	.box11 {
		padding: 1.6875em 0 0 0;
		position: relative;
		width: 100%;
	}
	.contenttext {
		margin: 0.625em 0 0 0;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #808080;
	}
	.contenttitle {
		font-size: 1em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #666666;
	}
	.box10content {
		padding: 1em 1.25em;
	}
	.box10 {
		width: 70%;
		height: 8.875em;
		background: #FFFFFF;
		border-radius: 0.25em;
		border: 1px solid #EEEEEE;
	}
	.box10title {
		width: 100%;
		height: 3.0625em;
		padding: 0 1em;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #EEEEEE;
		img {
			width: 1.625em;
			height: 1.625em;
			border-radius: 50%;
			margin: 0 0.5625em 0 0;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #555555;
		}
	}
	.oneinfo {
		padding: 0 1.25em 0 0;
		display: flex;
		justify-content: flex-end;
		position: relative;
		margin: 0 0 1.25em 0;
	}
	.point {
		width: 0.5625em;
		height: 0.5625em;
		border-radius: 50%;
		background: #EEEEEE;
		margin: 1.25em 0.625em 0;
	}
	.time {
		margin: 1em 0 0 0;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #808080;
		}
		p {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #666666;
		}
	}
	* {
		box-sizing: border-box;
	}
	.cluesdetail {
		width: 100%;
		
	}
	.box8 {
		position: absolute;
		width: calc(35% - 1.875em);
		// width: 0.9375rem;
		height: 100%;
		overflow: scroll;
		top: 0;
		right: 1.25em;
		// left: 60%;
		
		background-color: #ffffff;
	}
.oneshangji {
	width: 100%;
	background-color: #faf9fb;
	padding: 0.75em 1.6875em 0.9375em 0.9375em;
	margin: 0 0 0.9375em 0;
}
.shangjicontent {
	font-size: 0.875em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #808080;
	line-height: 1.45em;
}
.shangjititle {
	margin: 0 0 0.425em 0;
	font-size: 0.875em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
}
.onecontent {
	display: flex;
	align-items: flex-start;
	padding: 0.4em 0;
	p {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #333333;
		max-width: 70%;
		white-space: pre-wrap;
		word-break: break-all;
		display: flex;
		align-items: center;
		
	}
	span {
		color: #868E99;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
	}
	
}
.box7content {
	width: 100%;
	background-color: #ffffff;
	padding: 0.9375em 3em;
}
.titlebtn:active {
	opacity: 0.6;
}
.titlebtn {
	cursor: pointer;
	width: 5em;
	height: 2em;
	background: rgba(50, 134, 255, 0.1);
	border-radius: 0.25em;
	border: 1px solid #4d91ff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #3286ff;
}
.titletext {
	display: flex;
	align-items: center;
	div {
		width: 1.125em;
		height: 1.125em;
		background: #0091ff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
		border-radius: 50%;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0.625em 0 0;
	}
	span {
		font-size: 1em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
	}
}
.box7title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 1.25em;
	height: 3.125em;
	border-bottom: 1px solid #eeeeee;
}
.box7 {
	width: 100%;
	background-color: #ffffff;
	margin: 0 0 0.9375em 0;
}
.box6 {
	width: 65%;
	display: flex;
	flex-direction: column;
}
.box5 {
	// display: flex;
	width: 100%;
	padding: 0 1.25em 1.25em 0.9375em;
	position: relative;
}

.toptwobtn {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	div:active {
		opacity: 0.6;
	}
	div {
		margin: 0 0 0 1.875em;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 8.625em;
		height: 2.125em;
		border-radius: 0.25em;
		border: 1px solid #2d75f5;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #2e76f5;
		&.rightbtn {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
		}
	}
}
.box4 {
	margin: 0 0 0 0.625em;
	font-size: 1.125em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
}
.box3 {
	font-size: 1.125em;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #808080;
}
.box2 {
	margin: 0 0.625em 0 0;
	width: 0.1875em;
	height: 1.125em;
	background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
	border-radius: 0.125em;
}
.box1 {
	display: flex;
	align-items: center;
}
.topde {
	height: 3.875em;
	padding: 0 1.25em;
	margin: 0.9375em auto;
	width: calc(100% - 1.875em);
	display: flex;
	height: 2.5rem;
	background-color: #ffffff;
	align-items: center;
	justify-content: space-between;
}
@media screen and (max-width: 1920px) {
	.cluesdetail {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.cluesdetail {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.cluesdetail {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.cluesdetail {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.cluesdetail {
		font-size: 12px;
	}
}
</style>
